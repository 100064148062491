import React ,{ useState, useEffect } from 'react'  
import { BrowserRouter} from 'react-router-dom';
import Header from './views/Header';
import Sidenav from './views/Sidenav'; 
// import Activities from './views/Activity'; 
import Router from './routes/Router'; 
import './styles/scss/globalstyles.scss';

function App() {
  const [currPath, setCurrPath] = useState(window.location.pathname)
  useEffect(() => {
    setCurrPath(window.location.pathname)
}, [])
  return (
    <React.Fragment>       
      <BrowserRouter>
          <div className='layout_wrapper'>
            <Sidenav />
            <Header/>             
            <div className='main_content'>
             {/* {currPath !== '/login' && <></>} */}
             <Router/>
              {/* <div className='main_content_left'>
                
              </div>   */}
              {/* <Activities/>     */}
            </div>  
          </div>         
      </BrowserRouter> 
      
    </React.Fragment>
  );
}

export default App;
