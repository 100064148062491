import React, { useEffect, useState  }  from 'react';
import '../styles/scss/dashboard.scss';
 
import Logo from '../images/logo-blue.svg';

import RightArrow  from '../images/right.svg';
import DownArrow  from '../images/down-arrow.svg';
import RightArrowBlueCircle  from '../images/right-arrow-blue-circle.svg';
import TeamMember  from '../images/team_member.png';
import MoreMenu  from '../images/more.svg';
import SearchIcon  from '../images/search.svg';
import ChatIcon  from '../images/chat.svg';
import NotificationIcon  from '../images/notification.svg';
import ProfileIcon  from '../images/profile_icon.png';
import HelloHand  from '../images/hello_hand.png';
import PlusIcon  from '../images/plus.png';
import TasksSummary  from '../views/dashboard/TasksSummary';
import ProjectsSummary  from '../views/dashboard/ProjectsSummary';
import Activities from '../views/Activity';
function Dashboard() {

    useEffect(() => {

        document.body.classList.add('db_header_active')

        return () => {

          document.body.classList.remove('db_header_active')

        }

    }, [])

    

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const [state, setstate] = useState(false);
    const changeClass=()=>{
        const scrollValue=document.documentElement.scrollTop;
        if(scrollValue>25)
        {
        setstate(true);
        }
        else{
        setstate(false);
        }
        
    }
    window.addEventListener('scroll', changeClass);

    const data = [
        
        {
            projectId: '01',
            projectName: 'All Projects',
            projectNameShort: 'All'
        },
        {
            projectId: '02',
            projectName: 'Immibox',
            projectNameShort: 'IB'
        },
        {
            projectId: '03',
            projectName: 'ProfitX',
            projectNameShort: 'PF'
        },
        {
            projectId: '04',
            projectName: 'Qlytix',
            projectNameShort: 'QX'
        },
      ]


    const [projectData, setProjectData] = React.useState(data);
    
    const onChange = (e, projectId) => {
        const { name, value } = e.target
    
        const editData = projectData.map((item) =>
        item.projectId === projectId && name ? { ...item, [name]: value } : item
        )
    
        setProjectData(editData)
    }

    const daysData = [
        
        {
            dayId: '01',
            daysName: 'This Week',
        },
        {
            dayId: '02',
            daysName: 'Yesterday',
        },
        {
            dayId: '03',
            daysName: 'Today',
        },
      ]


    // const [daysallData, setdaysData] = React.useState(daysData);
    
    // const ondaysChange = (e, dayId) => {
    //     const { name, value } = e.target
    
    //     const editData = daysallData.map((item) =>
    //     item.dayId === dayId && name ? { ...item, [name]: value } : item
    //     )
    
    //     setdaysData(editData)
    // }

    return (
        <div className='db_page'>
            <div className='main_content_left'>
                <div className='db_wrapper'>
                    <header className={ state ? "db_header border" : "db_header"}>
                        <div className="left_cnt">
                        <div className='greeting_text'>
                            <span>Hello <em>Kumar</em></span> 
                            <img src={ HelloHand } />
                        </div>
                        </div>
                        <div className="right_cnt">
                            <button className="primary_btn add_more"><img src={ PlusIcon } /></button>
                            <div className="search_area">
                                <input type="text" placeholder="Company, Employee, Task, Sub Task" className="form_control" />
                                <img className="search_icon" src={ SearchIcon } />
                            </div>
                            <div className="message_notification">
                                <ul>
                                <li><img src={ NotificationIcon } /></li>
                                </ul>
                            </div>
                            <div className="profile_icon">
                                <img src={ ProfileIcon } />
                            </div>
                        </div>
                    </header>
                    <div className='widget all_projects_sec'>
                        <ProjectsSummary/>
                    </div>
                    <div className='widget team_task_sec'>
                        <TasksSummary/>
                    </div>
                </div>
            </div>
            <div className='main_content_right db_activities'>
                <Activities/>
            </div>
        </div>
    )
}


export default Dashboard;