import React ,{ useState, useEffect} from 'react'; 
//import '../styles/scss/forgotpassword.scss';  
import '../styles/scss/login.scss';
import '../styles/scss/components/simpleInput.scss';
 
 
 
//import { useHistory } from 'react-router-dom';

 
//import { Link} from 'react-router-dom';

function ForgotPassword() {
    const [email, setEmail] = useState("");

    useEffect(() => {
        document.body.classList.add('before-login')
        return () => {
          document.body.classList.remove('before-login')
        }
    }, [])

  
    return (
        <div className="login_page_wrapper"> 
            <div className="login_area">
                <h4>Forgot Password</h4>
                <div className="login-form">
                    <form>
                         
                    </form>
                </div>
            </div>
        </div>
    )
}


export default ForgotPassword;
