import React, { useState } from 'react';
import '../styles/scss/Header.scss';
import '../styles/scss/templates.scss';
import '../styles/scss/settings.scss';
import { Link, NavLink} from 'react-router-dom';
 
import Logo from '../images/logo-white.svg';
import DownArrow  from '../images/down-arrow.svg';
import MoreMenu  from '../images/more.svg';
import SearchIcon  from '../images/search.svg';
import ChatIcon  from '../images/chat.svg';
import NotificationIcon  from '../images/notification.svg';
import ProfileIcon  from '../images/profile_icon.png';
 
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Scrollbars } from 'react-custom-scrollbars';
 
import SideNav, { MenuIcon } from 'react-simple-sidenav';
import PlusIcon  from '../images/plus.png';
import { Input } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

function Header() {
    const [showNav, setShowNav] = useState();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
        } else if (event.key === 'Escape') {
        setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const[show,setShow]=useState(true);
    const [isActive, setActive] = useState("false");
    const [isActive2, setActive2] = useState("false");
    const [isActive3, setActive3] = useState("false");
    const [isActive4, setActive4] = useState("false");

    return (
    <header className="header">
        <MenuIcon onClick={() => setShowNav(true)} className="menu_icon"/>
        <div className="left_cnt">
            <div onClick={()=>setShow(!show)} className={!show ? "project_dropdown open" : "project_dropdown"}>
                <div className="project_logo">IB</div>
                <div className="project_desc">
                <span className="heading">PROJECTS</span>
                <span className="list">Immibox, Profitx, Qlytix</span>
                </div>
                <div className="more">+3</div>
                <img className="down-arrow" src={DownArrow} />
            </div>
            {
                !show?
                    <div className='project_dropdown_cnt'>
                    <ul>
                        <li className={!isActive ? "active" : null}>
                            <div className='top_sec'>
                                <div className='project_header'>
                                    <div className='project_logo'>IB</div>
                                    <div className='project_name'>Immibox</div>
                                </div>
                                <div className='projects_task_details'>
                                    <ul>
                                        <li className='all'><span>All</span> <em>25</em></li>
                                        <li className='new'><span>New</span> <em>12</em></li>
                                        <li className='overdue'><span>Overdue</span> <em>8</em></li>
                                        <li className='progress'><span>In-Progress</span> <em>8</em></li>
                                        <li className='hold'><span>On-Hold</span> <em>5</em></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='assignee_list'>
                                <ul>
                                    <li>
                                        <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                    </li>
                                    <li>
                                        <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                    </li>
                                    <li>
                                        <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                    </li>
                                    <Tooltip  enterTouchDelay={0}  arrow  placement="bottom-start"
                                        title={
                                            <React.Fragment>    
                                                <div className='actions-menu assignee_menu'>
                                                    <ul>
                                                         <li>
                                                            <Link>
                                                                <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                                                <div className='assignee_details'>
                                                                    <span className='assignee_name'>Kalyan Kumar</span>
                                                                </div>
                                                            </Link>                                                                    
                                                        </li>
                                                        <li>
                                                            <Link>
                                                                <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                                                <div className='assignee_details'>
                                                                    <span className='assignee_name'>Kalyan Kumar</span>
                                                                </div>
                                                            </Link>                                                                    
                                                        </li>
                                                        <li>
                                                            <Link>
                                                                <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                                                <div className='assignee_details'>
                                                                    <span className='assignee_name'>Kalyan Kumar</span>
                                                                </div>
                                                            </Link>  
                                                        </li>
                                                        <li>                                                                        
                                                            <Link>
                                                                <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                                                <div className='assignee_details'>
                                                                    <span className='assignee_name'>Kalyan Kumar</span>
                                                                </div>
                                                            </Link>  
                                                        </li>
                                                        <li>                                                                        
                                                            <Link>
                                                                <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                                                <div className='assignee_details'>
                                                                    <span className='assignee_name'>Kalyan Kumar</span>
                                                                </div>
                                                            </Link>  
                                                        </li>
                                                        <li>                                                                        
                                                            <Link>
                                                                <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                                                <div className='assignee_details'>
                                                                    <span className='assignee_name'>Kalyan Kumar</span>
                                                                </div>
                                                            </Link>  
                                                        </li>
                                                        <li>                                                                        
                                                            <Link>
                                                                <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                                                <div className='assignee_details'>
                                                                    <span className='assignee_name'>Kalyan Kumar</span>
                                                                </div>
                                                            </Link>  
                                                        </li>
                                                    </ul>
                                                </div>
                                            </React.Fragment>
                                        }
                                    > 
                                        <span className="more">+7</span>
                                    </Tooltip>
                                </ul>
                                <div className='channels'>
                                    <em>3</em>
                                    <span>Channels</span>
                                </div>
                            </div>
                            <label className='check_btn'>
                                <input type="checkbox" name="check" onClick={()=>setActive(!isActive)}/>
                                <span></span>
                            </label>
                        </li>
                        <li className={!isActive2 ? "active" : null}>
                            <div className='top_sec'>
                                <div className='project_header'>
                                    <div className='project_logo PF'>PF</div>
                                    <div className='project_name'>Profitx</div>
                                </div>
                                <div className='projects_task_details'>
                                    <ul>
                                        <li className='all'><span>All</span> <em>25</em></li>
                                        <li className='new'><span>New</span> <em>12</em></li>
                                        <li className='overdue'><span>Overdue</span> <em>8</em></li>
                                        <li className='progress'><span>In-Progress</span> <em>8</em></li>
                                        <li className='hold'><span>On-Hold</span> <em>5</em></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='assignee_list'>
                                <ul>
                                    <li>
                                        <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                    </li>
                                    <li>
                                        <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                    </li>
                                    <li>
                                        <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                    </li>
                                </ul>
                                <div className='channels'>
                                    <em>2</em>
                                    <span>Channels</span>
                                </div>
                            </div>
                            <label className='check_btn'>
                                <input type="checkbox" name="check" onClick={()=>setActive2(!isActive2)}/>
                                <span></span>
                            </label>
                        </li>
                        <li className={!isActive4 ? "active" : null}>
                            <div className='top_sec'>
                                <div className='project_header'>
                                    <div className='project_logo QX'>QX</div>
                                    <div className='project_name'>Qlytix</div>
                                </div>
                                <div className='projects_task_details'>
                                    <ul>
                                        <li className='all'><span>All</span> <em>25</em></li>
                                        <li className='new'><span>New</span> <em>12</em></li>
                                        <li className='overdue'><span>Overdue</span> <em>8</em></li>
                                        <li className='progress'><span>In-Progress</span> <em>8</em></li>
                                        <li className='hold'><span>On-Hold</span> <em>5</em></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='assignee_list'>
                                <ul>
                                    <li>
                                        <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                    </li>
                                    <li>
                                        <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                    </li>
                                    <li>
                                        <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                    </li>
                                    <Tooltip  enterTouchDelay={0}  arrow  placement="bottom-start"
                                        title={
                                            <React.Fragment>    
                                                <div className='actions-menu assignee_menu'>
                                                    <ul>
                                                         <li>
                                                            <Link>
                                                                <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                                                <div className='assignee_details'>
                                                                    <span className='assignee_name'>Kalyan Kumar</span>
                                                                </div>
                                                            </Link>                                                                    
                                                        </li>
                                                        <li>
                                                            <Link>
                                                                <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                                                <div className='assignee_details'>
                                                                    <span className='assignee_name'>Kalyan Kumar</span>
                                                                </div>
                                                            </Link>                                                                    
                                                        </li>
                                                        <li>
                                                            <Link>
                                                                <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                                                <div className='assignee_details'>
                                                                    <span className='assignee_name'>Kalyan Kumar</span>
                                                                </div>
                                                            </Link>  
                                                        </li>
                                                        <li>                                                                        
                                                            <Link>
                                                                <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                                                <div className='assignee_details'>
                                                                    <span className='assignee_name'>Kalyan Kumar</span>
                                                                </div>
                                                            </Link>  
                                                        </li>
                                                        <li>                                                                        
                                                            <Link>
                                                                <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                                                <div className='assignee_details'>
                                                                    <span className='assignee_name'>Kalyan Kumar</span>
                                                                </div>
                                                            </Link>  
                                                        </li>
                                                        <li>                                                                        
                                                            <Link>
                                                                <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                                                <div className='assignee_details'>
                                                                    <span className='assignee_name'>Kalyan Kumar</span>
                                                                </div>
                                                            </Link>  
                                                        </li>
                                                        <li>                                                                        
                                                            <Link>
                                                                <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                                                <div className='assignee_details'>
                                                                    <span className='assignee_name'>Kalyan Kumar</span>
                                                                </div>
                                                            </Link>  
                                                        </li>
                                                    </ul>
                                                </div>
                                            </React.Fragment>
                                        }
                                    > 
                                        <span className="more">+7</span>
                                    </Tooltip>
                                </ul>
                                <div className='channels'>
                                    <em>3</em>
                                    <span>Channels</span>
                                </div>
                            </div>
                            <label className='check_btn'>
                                <input type="checkbox" name="check" onClick={()=>setActive4(!isActive4)}/>
                                <span></span>
                            </label>
                        </li>
                        <li className={!isActive3 ? "active" : null}>
                            <div className='top_sec'>
                                <div className='project_header'>
                                    <div className='project_logo RM'>RM</div>
                                    <div className='project_name'>RMT</div>
                                </div>
                                <div className='projects_task_details'>
                                    <ul>
                                        <li className='all'><span>All</span> <em>25</em></li>
                                        <li className='new'><span>New</span> <em>12</em></li>
                                        <li className='overdue'><span>Overdue</span> <em>8</em></li>
                                        <li className='progress'><span>In-Progress</span> <em>8</em></li>
                                        <li className='hold'><span>On-Hold</span> <em>5</em></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='assignee_list'>
                                <ul>
                                    <li>
                                        <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                    </li>
                                    <li>
                                        <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                    </li>
                                    <li>
                                        <span className='assignee_avatar'><img src={ProfileIcon} /></span>
                                    </li>
                                </ul>
                                <div className='channels'>
                                    <em>2</em>
                                    <span>Channels</span>
                                </div>
                            </div>
                            <label className='check_btn'>
                                <input type="checkbox" name="check" onClick={()=>setActive3(!isActive3)}/>
                                <span></span>
                            </label>
                        </li>
                    </ul>
                </div>
                :null
            }
            <div className="kanban_timeline_list">
                <ul>
                <li className="active">List</li>
                <li>Kanban</li>
                <li>Timeline</li>
                <li><img src={MoreMenu} /></li>
                </ul> 
            </div>
        </div>
        <div className="right_cnt">
            <button className="primary_btn add_more"><img src={PlusIcon} /></button>
            <div className="search_area">
                <input type="text" placeholder="Search" className="form_control" />
                <img className="search_icon" src={ SearchIcon } />
            </div>
            <div className="message_notification">
                <ul>
                <li><img src={ ChatIcon } /></li>
                <li><img src={ NotificationIcon } /></li>
                </ul>
            </div>
            <div className="profile_icon">
                <img src={ ProfileIcon } />
            </div>
        </div>
    </header>
        
        
    )
}


export default Header;