import React from 'react'; 
import { NavLink} from 'react-router-dom';
import '../styles/scss/Sidenav.scss';

import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Scrollbars } from 'react-custom-scrollbars';

import Logo from '../images/logo-white.svg';
import LogoText from '../images/logo_text.png';
 


function Sidenav() { 

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
        } else if (event.key === 'Escape') {
        setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);


    return (
        <div className='sidebar'> 
            <div className='sidebar_cnt'>
            <div className='logo'>
                <NavLink exact to="/dashboard" activeClassName='active'>
                    <img src={Logo} alt="Excelery Logo" /><span><img src={LogoText} alt="Excelery Logo" /></span>
                </NavLink>
            </div>
                <Scrollbars style={{ height: "calc(100vh - 175px)"  }} className='menu'>
                    <ul>
                        <li className='dashboard'>
                            <NavLink  exact to="/dashboard" activeClassName='active'>
                                <span className='menu_icon'></span>
                                <span className='menu_label'>dashboard</span>
                            </NavLink>
                        </li>
                        <li className='projects'>
                            <NavLink to="/projects" activeClassName='active'>
                                <span className='menu_icon'></span>
                                <span className='menu_label'>projects</span>
                            </NavLink>
                        </li>
                        {/* <li className='channels'>
                            <NavLink to="/channels" activeClassName='active'>
                                <span className='menu_icon'></span>
                                <span className='menu_label'>channels</span>
                            </NavLink>
                        </li>
                        <li className="team">
                            <NavLink to="/channels" activeClassName="active">
                                <span className='menu_icon'></span>
                                <span className='menu_label'>team</span>
                            </NavLink>
                        </li>
                        <li className="timesheet">
                            <NavLink to="/timesheet" activeClassName="active">
                                <span className='menu_icon'></span>
                                <span className='menu_label'>timesheet</span>
                            </NavLink>
                        </li>
                        <li className='documents'>
                            <NavLink to="/documents" activeClassName='active'>
                                <span className='menu_icon'></span>
                                <span className='menu_label'>documents</span>
                            </NavLink>
                        </li> */}
                    </ul>
                </Scrollbars>
                <div className='menu bottom_cnt'>
                    <ul>
                        <li className="settings">
                            <NavLink to="/settings" activeClassName="active">
                                <span className='menu_icon'></span>
                                <span className='menu_label'>settings</span>
                            </NavLink>
                        </li>
                        <li className='logout'>
                            <NavLink to="/logout" activeClassName='active'>
                                <span className='menu_icon'></span>
                                <span className='menu_label'>logout</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>

            
        </div>
    )
}


export default Sidenav;