import React, { useState, useEffect } from 'react'; 
import '../styles/scss/Activity.scss';
import Horizontal_menu from '../images/h_dots.svg';
import ProfileDP from '../images/activities_icon.png';
import DocIcon from '../images/doc.svg'; 
import { Link } from 'react-router-dom'; 
import { Scrollbars } from 'react-custom-scrollbars';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'; 
import Tooltip from '@mui/material/Tooltip';
import Select from 'react-select' 
import MoreMenu  from '../images/more.svg';
function Activity() {  
  
    const [showTab, setShowTab] = useState(false);
    useEffect(() => {
        document.body.classList.toggle('right-menu-open', showTab);
    },[showTab])

  

    const options = [
        { value: '01', label: 'All' },
        { value: '02', label: 'Yesterday' },
        { value: '03', label: 'Today' }
    ]

    const clientOptions = [
        { value: '01', label: 'Michael' },
        { value: '02', label: 'Oliver' },
        { value: '03', label: 'Alexander' }
    ]

    const ChannelsOptions = [
        { value: '01', label: 'Channel A' },
        { value: '02', label: 'Channel B' },
        { value: '03', label: 'Channel C' }
    ]

    
  
    return (
        <div className='right-side-nav'> 
            <div className='activity_tabs'>
                <span onClick={()=>setShowTab(showTab = !showTab)} className={showTab ? "menu_btn active" : "menu_btn"}></span>
                <div className="tabs_wrapper">
                    <Tabs>
                        <TabList>
                            <Tab>
                                <p>Activities<span className='count'>12</span></p>
                            </Tab>
                            <Tab>
                                <p>Channels<span className='count'>9</span></p>
                            </Tab>
                        </TabList>
                        <TabPanel>
                            <div className="filter_sec">                                
                                 <Select className='custom_select'
                                    closeMenuOnSelect={true}
                                    defaultValue={options[0]}
                                    classNamePrefix="custom_select_inner"
                                    styles={{
                                        option: (base) => ({
                                          ...base,
                                          fontFamily: `$primaryfontMedium`,
                                          fontSize: '12px',
                                          height: '100%',
                                          fontWeight: '500'
                                        }),
                                      }}                              
                                    options={options} 
                                />
                            </div>
                            <Scrollbars style={{ height: "calc(100vh - 185px)"  }}  className='active-scroll'>
                                <div className="activities_list">
                                    <div className="activity_item">
                                        <div className="activity_heading">
                                            <div className="left_cnt">
                                                <div className="profile_icon"> 
                                                    <img src={ProfileDP} alt="Profile Image" />
                                                    
                                                </div>
                                                <h6>Jessie Freeman</h6>
                                            </div>
                                            <div className="right_cnt">
                                                <span className="time">4:20pm</span> 
                                                <div className="more_menu">
                                                    <Tooltip  enterTouchDelay={0}  arrow  placement="bottom-start"
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='actions-menu'>
                                                                    <ul>
                                                                        <li>
                                                                            <Link>Edit</Link>                                                                        
                                                                        </li>
                                                                        <li>
                                                                            <Link>Delete</Link>
                                                                        </li>                                                 
                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <span><img src={ MoreMenu } /></span>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="activity_desc">
                                            <h6>Development Issue #45 resolved</h6>
                                        </div>
                                    </div>
                                    <div className="timeline-day">
                                        <span>Yesturday</span>
                                    </div>
                                    <div className="activity_item">
                                        <div className="activity_heading">
                                            <div className="left_cnt">
                                                <div className="profile_icon">
                                                    <img src={ProfileDP} alt="Profile Image" />
                                                </div>
                                                <h6>Garrett McCormik</h6>
                                            </div>
                                            <div className="right_cnt">
                                                <span className="time">4:20pm</span>
                                                <div className="more_menu">
                                                    <Tooltip  enterTouchDelay={0}  arrow  placement="bottom-start"
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='actions-menu'>
                                                                    <ul>
                                                                        <li>
                                                                            <Link>Edit</Link>                                                                        
                                                                        </li>
                                                                        <li>
                                                                            <Link>Delete</Link>
                                                                        </li>                                                 
                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <span><img src={ MoreMenu } /></span>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="activity_desc">
                                            <h6>Meeting with marketing team</h6>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting…</p>
                                            <button className="primary_btn">Accept</button>
                                        </div>
                                    </div>
                                    <div className="activity_item">
                                        <div className="activity_heading">
                                            <div className="left_cnt">
                                                <div className="profile_icon">
                                                    <img src={ProfileDP} alt="Profile Image" />
                                                </div>
                                                <h6>McCormik Garrett</h6>
                                            </div>
                                            <div className="right_cnt">
                                                <span className="time">4:20pm</span>
                                                <div className="more_menu">
                                                    <Tooltip  enterTouchDelay={0}  arrow  placement="bottom-start"
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='actions-menu'>
                                                                    <ul>
                                                                        <li>
                                                                            <Link>Edit</Link>                                                                        
                                                                        </li>
                                                                        <li>
                                                                            <Link>Delete</Link>
                                                                        </li>                                                 
                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <span><img src={ MoreMenu } /></span>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="activity_desc">
                                            <h6>Design changes updated</h6>
                                        </div>
                                    </div>
                                    <div className="activity_item">
                                        <div className="activity_heading">
                                            <div className="left_cnt">
                                                <div className="profile_icon">
                                                    <img src={ProfileDP} alt="Profile Image" />
                                                </div>
                                                <h6>Jessie Freeman</h6>
                                            </div>
                                            <div className="right_cnt">
                                                <span className="time">4:20pm</span>
                                                <div className="more_menu">
                                                    <Tooltip  enterTouchDelay={0}  arrow  placement="bottom-start"
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='actions-menu'>
                                                                    <ul>
                                                                        <li>
                                                                            <Link>Edit</Link>                                                                        
                                                                        </li>
                                                                        <li>
                                                                            <Link>Delete</Link>
                                                                        </li>                                                 
                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <span><img src={ MoreMenu } /></span>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="activity_desc">
                                            <h6>Discussion about new project</h6>
                                            <button className="primary_btn">Invite</button>
                                        </div>
                                    </div>
                                    <div className="timeline-day">
                                        <span>Today</span>
                                    </div>
                                    <div className="activity_item">
                                        <div className="activity_heading">
                                            <div className="left_cnt">
                                                <div className="profile_icon">
                                                    <img src={ProfileDP} alt="Profile Image" />
                                                </div>
                                                <h6>Micheal Peterson</h6>
                                            </div>
                                            <div className="right_cnt">
                                                <span className="time">4:20pm</span>
                                                <div className="more_menu">
                                                    <Tooltip  enterTouchDelay={0}  arrow  placement="bottom-start"
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='actions-menu'>
                                                                    <ul>
                                                                        <li>
                                                                            <Link>Edit</Link>                                                                        
                                                                        </li>
                                                                        <li>
                                                                            <Link>Delete</Link>
                                                                        </li>                                                 
                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <span><img src={ MoreMenu } /></span>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="activity_desc">
                                            <h6>5 Tasks overdue</h6>
                                            <button className="secondary_btn">View</button>
                                        </div>
                                    </div>
                                </div>
                            </Scrollbars>
                        </TabPanel>
                        <TabPanel>
                            <div className="filter_sec">
                                <Select className='custom_select'
                                    closeMenuOnSelect={true}
                                    classNamePrefix="custom_select_inner"
                                    styles={{
                                        option: (base) => ({
                                          ...base,
                                          fontFamily: `$primaryfontMedium`,
                                          fontSize: '12px',
                                          height: '100%',
                                          fontWeight: '500'
                                        }),
                                      }}                              
                                    options={clientOptions} 
                                    placeholder="Select client"
                                />                               
                                 <Select className='custom_select'
                                    closeMenuOnSelect={true}
                                    classNamePrefix="custom_select_inner"
                                    styles={{
                                        option: (base) => ({
                                          ...base,
                                          fontFamily: `$primaryfontMedium`,
                                          fontSize: '12px',
                                          height: '100%',
                                          fontWeight: '500'
                                        }),
                                      }}                              
                                    options={ChannelsOptions} 
                                    placeholder="Select Channels"
                                />                                  
                            </div>
                            <Scrollbars style={{ height: "calc(100vh - 185px)"  }}  className='active-scroll'>
                                <div className="activities_list">
                                    <div className="activity_item">
                                        <div className="activity_heading">
                                            <div className="left_cnt">
                                                <div className="profile_icon"> 
                                                    <span className='IB'>IB</span>
                                                </div>
                                                <h6>Garrett McCormik</h6>
                                            </div>
                                            <div className="right_cnt">
                                                <span className="time">4:20pm</span> 
                                                <div className="more_menu">
                                                    <Tooltip  enterTouchDelay={0}  arrow  placement="bottom-start"
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='actions-menu'>
                                                                    <ul>
                                                                        <li>
                                                                            <Link>Edit</Link>                                                                        
                                                                        </li>
                                                                        <li>
                                                                            <Link>Delete</Link>
                                                                        </li>                                                 
                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <span><img src={ MoreMenu } /></span>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="activity_desc">
                                            <h6 className='small'>Added 4 files to <span>Design System</span></h6>
                                            <div className='file_sec'>
                                                <div className='file_list'>
                                                    <img src={DocIcon} />
                                                    <div className='file_info'>
                                                        <span>Onboarding Information</span>
                                                        <em>12 mb</em>
                                                    </div>
                                                </div>
                                                <Tooltip  enterTouchDelay={0}  arrow  placement="bottom-start"
                                                    title={
                                                        <React.Fragment>    
                                                            <div className='actions-menu file_list_menu'>
                                                                <ul>
                                                                    <li>
                                                                        <div className='file_list'>
                                                                            <img src={DocIcon} />
                                                                            <div className='file_info'>
                                                                                <span>Onboarding Information</span>
                                                                                <em>12 mb</em>
                                                                            </div>
                                                                        </div>                                                                    
                                                                    </li>
                                                                    <li>
                                                                        <div className='file_list'>
                                                                            <img src={DocIcon} />
                                                                            <div className='file_info'>
                                                                                <span>Onboarding Information</span>
                                                                                <em>12 mb</em>
                                                                            </div>
                                                                        </div>                                                                    
                                                                    </li>
                                                                    <li>
                                                                        <div className='file_list'>
                                                                            <img src={DocIcon} />
                                                                            <div className='file_info'>
                                                                                <span>Onboarding Information</span>
                                                                                <em>12 mb</em>
                                                                            </div>
                                                                        </div>                                                                    
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                > 
                                                    <span className='more_files'>+3</span>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="timeline-day">
                                        <span>Yesturday</span>
                                    </div>
                                    <div className="activity_item">
                                        <div className="activity_heading">
                                            <div className="left_cnt">
                                                <div className="profile_icon"> 
                                                    <span className='PF'>PF</span>
                                                </div>
                                                <h6>Jessie Freeman</h6>
                                            </div>
                                            <div className="right_cnt">
                                                <span className="time">4:20pm</span> 
                                                <div className="more_menu">
                                                    <Tooltip  enterTouchDelay={0}  arrow  placement="bottom-start"
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='actions-menu'>
                                                                    <ul>
                                                                        <li>
                                                                            <Link>Edit</Link>                                                                        
                                                                        </li>
                                                                        <li>
                                                                            <Link>Delete</Link>
                                                                        </li>                                                 
                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <span><img src={ MoreMenu } /></span>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="activity_desc">
                                            <h6 className='small'>Completed task <span>Information Architecture</span></h6>
                                        </div>
                                    </div>
                                    <div className="activity_item">
                                        <div className="activity_heading">
                                            <div className="left_cnt">
                                                <div className="profile_icon"> 
                                                    <span className='RM'>RM</span>
                                                </div>
                                                <h6>Micheal Peterson</h6>
                                            </div>
                                            <div className="right_cnt">
                                                <span className="time">4:20pm</span> 
                                                <div className="more_menu">
                                                    <Tooltip  enterTouchDelay={0}  arrow  placement="bottom-start"
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='actions-menu'>
                                                                    <ul>
                                                                        <li>
                                                                            <Link>Edit</Link>                                                                        
                                                                        </li>
                                                                        <li>
                                                                            <Link>Delete</Link>
                                                                        </li>                                                 
                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <span><img src={ MoreMenu } /></span>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="activity_desc">
                                            <h6 className='small'>I am so excited! Any updated on design prototype?</h6>
                                        </div>
                                    </div>
                                    <div className="timeline-day">
                                        <span>Today</span>
                                    </div>
                                    <div className="activity_item">
                                        <div className="activity_heading">
                                            <div className="left_cnt">
                                                <div className="profile_icon"> 
                                                    <span className='IB'>IB</span>
                                                </div>
                                                <h6>Garrett McCormik</h6>
                                            </div>
                                            <div className="right_cnt">
                                                {/* <span className="time">4:20pm</span>  */}
                                                <div className="more_menu">
                                                    <Tooltip  enterTouchDelay={0}  arrow  placement="bottom-start"
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='actions-menu'>
                                                                    <ul>
                                                                        <li>
                                                                            <Link>Edit</Link>                                                                        
                                                                        </li>
                                                                        <li>
                                                                            <Link>Delete</Link>
                                                                        </li>                                                 
                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <span><img src={ MoreMenu } /></span>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="activity_desc">
                                            <h6 className='small'>Added 4 files to <span>Design System</span></h6>
                                            <div className='file_sec'>
                                                <div className='file_list'>
                                                    <img src={DocIcon} />
                                                    <div className='file_info'>
                                                        <span>Onboarding Information</span>
                                                        <em>12 mb</em>
                                                    </div>
                                                </div>
                                                <Tooltip  enterTouchDelay={0}  arrow  placement="bottom-start"
                                                    title={
                                                        <React.Fragment>    
                                                            <div className='actions-menu file_list_menu'>
                                                                <ul>
                                                                    <li>
                                                                        <div className='file_list'>
                                                                            <img src={DocIcon} />
                                                                            <div className='file_info'>
                                                                                <span>Onboarding Information</span>
                                                                                <em>12 mb</em>
                                                                            </div>
                                                                        </div>                                                                    
                                                                    </li>
                                                                    <li>
                                                                        <div className='file_list'>
                                                                            <img src={DocIcon} />
                                                                            <div className='file_info'>
                                                                                <span>Onboarding Information</span>
                                                                                <em>12 mb</em>
                                                                            </div>
                                                                        </div>                                                                    
                                                                    </li>
                                                                    <li>
                                                                        <div className='file_list'>
                                                                            <img src={DocIcon} />
                                                                            <div className='file_info'>
                                                                                <span>Onboarding Information</span>
                                                                                <em>12 mb</em>
                                                                            </div>
                                                                        </div>                                                                    
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                > 
                                                    <span className='more_files'>+3</span>
                                                </Tooltip>
                                            </div>
                                            <span className='time-helptext'>Yesturday, 8:20 PM</span>
                                        </div>
                                    </div>
                                </div>
                            </Scrollbars>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}


export default Activity;