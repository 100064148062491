import React, { useState } from 'react';
import DownArrow  from '../images/down-arrow.svg';
import DownArrowFilled  from '../images/down_arrow_filled.svg';
import MoreMenu  from '../images/more.svg';
import SearchIcon  from '../images/search.svg';
import ChatIcon  from '../images/chat.svg';
import NotificationIcon  from '../images/notification.svg';
import ProfileIcon  from '../images/profile_icon.png';
import FiterIcon  from '../images/actions/filter.svg';
import FunnelIcon  from '../images/actions/funnel.svg';
import EyeIcon  from '../images/actions/eye.svg';
import CommentIcon  from '../images/actions/comment.svg';
import CommentIconActive  from '../images/actions/commentactive.svg';
import MoveIcon  from '../images/move.svg';
import Logo from '../images/logo-blue.svg';
import Assignee  from '../images/actions/add_assignee.svg';
import DueDate  from '../images/actions/due_date.svg';
import Priority  from '../images/actions/priority.svg';
import Tooltip from '@mui/material/Tooltip';
import { Link} from 'react-router-dom';  
import { Input } from '@mui/material';

import Check  from '../images/actions/check.svg';
import Delete  from '../images/actions/delete.svg';
import Duplication  from '../images/actions/duplication.svg';
import Edit  from '../images/actions/Edit.svg';
import Hierarchy  from '../images/actions/hierarchy.svg';
import Share  from '../images/actions/Share.svg';
import TeamMember  from '../images/team_member.png';
import '../styles/scss/table.scss';
import Select from 'react-select' 
import MenuItem from '@mui/material/MenuItem';
import TestComp from '../views/Test'; 
import Checkbox from '@mui/material/Checkbox';
import Activities from '../views/Activity';

function Projects() {
    const[show,setShow]=useState(true);
    const[showTable,setShowTable]=useState(true);
    const[showTable2,setShowTable2]=useState(true);
    
    const assigneeOptions = [
        { value: 'R', label: 'Ravi Kumar' },
        { value: 'M', label: 'Madhu Sanisetty' },
        { value: 'S', label: 'Syam Kumar' }
    ]

    const priorityOptions = [
        { value: 'high', label: 'High' , className:"syam2" },
        { value: 'normal', label: 'Normal' , className:"syam3" },
        { value: 'low', label: 'Low', className:"syam" }
    ]

    const statusOptions = [
        { value: 'progress', label: 'In-Progress' },
        { value: 'overdue', label: 'OverDue' },
        { value: 'completed', label: 'Completed' }
    ]
    
    const data = [
        {
            projectId: '01',
            projectName: 'Dashboard Requirements Discussions',
            assignee: 'R',
            dueDate: '2023-04-20',
            priority: 'high',
            prStatus: 'progress',
        },
        {
            projectId: '02',
            projectName: 'Assets Export',
            assignee: 'M',
            dueDate: '2023-04-28',
            priority: 'normal',
            prStatus: 'overdue'
        },
        {
            projectId: '03',
            projectName: 'Calendar View UX/UI Designs',
            assignee: 'S',
            dueDate: '2023-05-26',
            priority: 'low',
            prStatus: 'completed'
        },
      ]

    const [projectData, setProjectData] = React.useState(data);
    
    const onChange = (e, projectId) => {
        const { name, value } = e.target
    
        const editData = projectData.map((item) =>
        item.projectId === projectId && name ? { ...item, [name]: value } : item
        )
    
        setProjectData(editData)
    }

    // //////////

    const [enteredText, setEnteredText] = useState("");
    const [submittedText, setSubmittedText] = useState(null);

    const textChangeHandler = (i) => {
        setEnteredText(i.target.value);
    };
    
    const submitHandler = (event) => {
        event.preventDefault();
        setSubmittedText(enteredText);
        setEnteredText("");
    };

    const tableDisplay = submittedText;
    // //////

    return (
        <React.Fragment>
            <div className='main_content_left'>
                <div className='w-full'>
                    <ul className="filter_actions">
                        <li><span className="mytasks">My Tasks <em>5</em></span></li>
                        <li className='filter'></li>
                        <li className='funnel'></li>
                        <li className='eye'></li>            
                    </ul>
                    <div className="table_responsive">
                        <div className="table_header">
                            <div className="project_cnt" onClick={()=>setShow(!show)}>
                            <img src={ DownArrowFilled } className={!show ? null : "collapsed"} />
                            <span className="project_logo"><em>IB</em></span>
                            <span className="project_name">Immibox</span>
                            </div>
                            <span className="more">
                            <Tooltip  enterTouchDelay={0}  arrow  placement="bottom-start"
                                title={
                                    <React.Fragment>    
                                        <div className='actions-menu'>
                                            <ul>
                                                <li>
                                                    <Link><img src={ Check } /> New Task</Link>                                                                        
                                                </li>
                                                <li>
                                                    <Link><img src={ Hierarchy } /> Create Group</Link>
                                                </li>
                                                <li>                                                                        
                                                    <Link><img src={ Edit } /> Rename</Link>
                                                </li>
                                                <li>                                                                        
                                                    <Link><img src={ Duplication } /> Duplicate</Link>
                                                </li>
                                                <li>                                                                        
                                                    <Link><img src={ Share } /> Share</Link>
                                                </li>
                                                <li>                                                                        
                                                    <Link><img src={ Delete } /> Delete</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                }
                            > 
                                <img src={ MoreMenu } />
                            </Tooltip>
                            </span>
                        </div>
                        <table className={show ? "projects_table" : "projects_table collapsed"}>
                            <thead>
                            <tr>
                                <th>Task name</th>
                                <th>Assignee</th>
                                <th>Due Date</th>
                                <th>Priority</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr className={!showTable ? null : "open_head"}>
                                <td colSpan={5}>
                                <div className="collapsing_header">
                                    <span className="move_row"><img src={ MoveIcon } /></span>
                                    <span className="task_heading" onClick={()=>setShowTable(!showTable)}> <img src={ DownArrowFilled } className={!showTable ? null : "collapsed"} /> UX/UI Designs</span>
                                    <span className="more">
                                    <Tooltip  enterTouchDelay={0}  arrow  placement="bottom"
                                        title={
                                            <React.Fragment>    
                                                <div className='actions-menu'>
                                                    <ul>
                                                        <li>
                                                            <Link><img src={ Check } /> New Task</Link>                                                                        
                                                        </li>
                                                        <li>
                                                            <Link><img src={ Hierarchy } /> Create Group</Link>
                                                        </li>
                                                        <li>                                                                        
                                                            <Link><img src={ Edit } /> Rename</Link>
                                                        </li>
                                                        <li>                                                                        
                                                            <Link><img src={ Duplication } /> Duplicate</Link>
                                                        </li>
                                                        <li>                                                                        
                                                            <Link><img src={ Share } /> Share</Link>
                                                        </li>
                                                        <li>                                                                        
                                                            <Link><img src={ Delete } /> Delete</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </React.Fragment>
                                        }
                                    > 
                                        <img src={ MoreMenu } />
                                    </Tooltip>
                                    </span>
                                </div>
                                </td>
                            </tr>
                            {projectData.map(({ projectId, projectName, assignee, dueDate, priority, prStatus }) => (
                                <tr className={showTable ? 'open_head' : "collapsed"} key={projectId}>
                                    <td>
                                        <input
                                            className='autofocus'
                                            name="projectName"
                                            value={projectName}
                                            type="text"
                                            onChange={(e) => onChange(e, projectId)}
                                            placeholder="+ Write a task name" />
                                    </td>
                                    <td>
                                        <span className="assignee">
                                            <em className='bg_light_green'>{ assignee }</em>
                                            <Select className='custom_select assignee_select'
                                                closeMenuOnSelect={true}
                                                classNamePrefix="custom_select_inner"
                                                defaultValue={assigneeOptions[0]}
                                                styles={{
                                                    option: (base) => ({
                                                    ...base,
                                                    fontFamily: `$primaryfontMedium`,
                                                    fontSize: '12px',
                                                    height: '100%',
                                                    fontWeight: '500'
                                                    }),
                                                }}
                                                options={assigneeOptions}
                                            />
                                        </span>
                                    </td>
                                    <td className='date_column'>
                                        <input
                                        className='autofocus'
                                        name="dueDate"
                                        value={dueDate}
                                        type="date"
                                        onChange={(e) => onChange(e, projectId)} />
                                        
                                    </td>
                                    <td>
                                        {/* <span className={`priority ${ priority }`}> */}
                                        <span>
                                        {/* <Select autofocus
                                            value={priority}
                                            name="priority"
                                            onChange={(e) => onChange(e, projectId)}
                                            className='customselect table_select'
                                            >
                                            <MenuItem value={'high'}>High</MenuItem>
                                            <MenuItem value={'normal'}>Normal</MenuItem>
                                            <MenuItem value={'low'}>Low</MenuItem>
                                        </Select> */}
                                        <Select className='custom_select assignee_select'
                                            closeMenuOnSelect={true}
                                            classNamePrefix="custom_select_inner"
                                            defaultValue={priorityOptions[2]}
                                            styles={{
                                                option: (base) => ({
                                                ...base,
                                                fontFamily: `$primaryfontMedium`,
                                                fontSize: '12px',
                                                height: '100%',
                                                fontWeight: '500'
                                                }),
                                            }}
                                            options={priorityOptions}
                                        />
                                        </span>
                                    </td>
                                    <td>
                                        {/* <span className={`status ${ prStatus }`}> */}
                                        <span>
                                        {/* <Select
                                            value={prStatus}
                                            name="prStatus"
                                            onChange={(e) => onChange(e, projectId)}
                                            className='customselect table_select'
                                            >
                                            <MenuItem value={'progress'}>In-Progress</MenuItem>
                                            <MenuItem value={'overdue'}>Overdue</MenuItem>
                                            <MenuItem value={'completed'}>Completed</MenuItem>
                                        </Select> */}

                                        <Select className='custom_select assignee_select'
                                            closeMenuOnSelect={true}
                                            classNamePrefix="custom_select_inner"
                                            defaultValue={statusOptions[1]}
                                            styles={{
                                                option: (base) => ({
                                                ...base,
                                                fontFamily: `$primaryfontMedium`,
                                                fontSize: '12px',
                                                height: '100%',
                                                fontWeight: '500'
                                                }),
                                            }}
                                            options={statusOptions}
                                        />
                                        </span>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={5}>
                                <div className="collapsing_header">
                                    <span className="move_row"><img src={ MoveIcon } /></span>
                                    <span className="task_heading" onClick={()=>setShowTable2(!showTable2)}> <img src={ DownArrowFilled } className={!showTable2 ? "collapsed" : null} /> Upcoming Sprint</span>
                                    <span className="more">
                                    <Tooltip  enterTouchDelay={0}  arrow  placement="bottom"
                                        title={
                                            <React.Fragment>    
                                                <div className='actions-menu'>
                                                    <ul>
                                                        <li>
                                                            <Link><img src={ Check } /> New Task</Link>                                                                        
                                                        </li>
                                                        <li>
                                                            <Link><img src={ Hierarchy } /> Create Group</Link>
                                                        </li>
                                                        <li>                                                                        
                                                            <Link><img src={ Edit } /> Rename</Link>
                                                        </li>
                                                        <li>                                                                        
                                                            <Link><img src={ Duplication } /> Duplicate</Link>
                                                        </li>
                                                        <li>                                                                        
                                                            <Link><img src={ Share } /> Share</Link>
                                                        </li>
                                                        <li>                                                                        
                                                            <Link><img src={ Delete } /> Delete</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </React.Fragment>
                                        }
                                    > 
                                        <img src={ MoreMenu } />
                                    </Tooltip>
                                    </span>
                                    <div className='project_status'>
                                        <ul>
                                            <li className='new'><em>4</em> New</li>
                                            <li className='completed'><em>5</em> Completed</li>
                                            <li className='progress'><em>4</em> In-Progress</li>
                                            <li className='overdue'><em>3</em> Overdue</li>
                                            <li className='delayed'><em>1</em> Delayed</li>
                                        </ul>
                                    </div>
                                </div>
                                </td>
                            </tr>
                            {projectData.map(({ projectId, projectName, assignee, dueDate, priority, prStatus }) => (
                                <tr className={showTable2 ? 'collapsed' : "open_head"} key={projectId}>
                                    <td>
                                        <input
                                            name="projectName"
                                            value={projectName}
                                            type="text"
                                            onChange={(e) => onChange(e, projectId)}
                                            placeholder="+ Write a task name" />
                                    </td>
                                    <td>
                                        <span className="assignee">
                                            <em className='bg_light_green'>{ assignee }</em>
                                            <Select className='custom_select assignee_select'
                                                closeMenuOnSelect={true}
                                                classNamePrefix="custom_select_inner"
                                                defaultValue={assigneeOptions[0]}
                                                styles={{
                                                    option: (base) => ({
                                                    ...base,
                                                    fontFamily: `$primaryfontMedium`,
                                                    fontSize: '12px',
                                                    height: '100%',
                                                    fontWeight: '500'
                                                    }),
                                                }}
                                                options={assigneeOptions}
                                            />
                                        </span>
                                    </td>
                                    <td className='date_column'>
                                        <input
                                        name="dueDate"
                                        value={dueDate}
                                        type="date"
                                        onChange={(e) => onChange(e, projectId)} />
                                        
                                    </td>
                                    <td>
                                         
                                        <Select className='custom_select assignee_select'
                                            closeMenuOnSelect={true}
                                            classNamePrefix="custom_select_inner"
                                            defaultValue={priorityOptions[2]}
                                            styles={{
                                                option: (base) => ({
                                                ...base,
                                                fontFamily: `$primaryfontMedium`,
                                                fontSize: '12px',
                                                height: '100%',
                                                fontWeight: '500'
                                                }),
                                            }}
                                            options={priorityOptions}
                                        />
                                         
                                    </td>
                                    <td>
                                        <Select className='custom_select assignee_select'
                                            closeMenuOnSelect={true}
                                            classNamePrefix="custom_select_inner"
                                            defaultValue={statusOptions[1]}
                                            styles={{
                                                option: (base) => ({
                                                ...base,
                                                fontFamily: `$primaryfontMedium`,
                                                fontSize: '12px',
                                                height: '100%',
                                                fontWeight: '500'
                                                }),
                                            }}
                                            options={statusOptions}
                                        />
                                    </td>
                                </tr>
                            ))}
                            {projectData.map(({ projectId, projectName, assignee, dueDate, priority, prStatus }) => (
                            <tr>
                                <td>
                                <div className="collapsing_header">
                                    <span className="move_row"><img src={ MoveIcon } /></span>                                     
                                    <input
                                        name="projectName"
                                        value={projectName}
                                        type="text"
                                        onChange={(e) => onChange(e, projectId)}
                                        placeholder="+ Write a task name" />
                                </div>
                                </td>
                                <td>
                                <span className="assignee"><em className='bg_light_green'>S</em> <em className='bg_light_blue'>R</em> <em className='bg_light_pink'>K</em></span>
                                </td> 
                                <td className='date_column'>
                                        <input
                                        name="dueDate"
                                        value={dueDate}
                                        type="date"
                                        onChange={(e) => onChange(e, projectId)} />
                                        
                                    </td>
                                    <td>
                                         
                                         <Select className='custom_select assignee_select'
                                             closeMenuOnSelect={true}
                                             classNamePrefix="custom_select_inner"
                                             defaultValue={priorityOptions[2]}
                                             styles={{
                                                 option: (base) => ({
                                                 ...base,
                                                 fontFamily: `$primaryfontMedium`,
                                                 fontSize: '12px',
                                                 height: '100%',
                                                 fontWeight: '500'
                                                 }),
                                             }}
                                             options={priorityOptions}
                                         />
                                          
                                     </td>
                                     <td>
                                         <Select className='custom_select assignee_select'
                                             closeMenuOnSelect={true}
                                             classNamePrefix="custom_select_inner"
                                             defaultValue={statusOptions[1]}
                                             styles={{
                                                 option: (base) => ({
                                                 ...base,
                                                 fontFamily: `$primaryfontMedium`,
                                                 fontSize: '12px',
                                                 height: '100%',
                                                 fontWeight: '500'
                                                 }),
                                             }}
                                             options={statusOptions}
                                         />
                                     </td>
                            </tr>
                            ))}
                             
                            </tbody>
                        </table>
                    </div>
                    <div className="table_responsive">
                        <div className="table_header">
                            <div className="project_cnt">
                            <span className="project_logo"><img src={Logo} /></span>
                            <span className="project_name">Exerly</span>
                            </div>
                            <span className="more">
                            <Tooltip  enterTouchDelay={0}  arrow  placement="bottom-start"
                                title={
                                    <React.Fragment>    
                                        <div className='actions-menu'>
                                            <ul>
                                                <li>
                                                    <Link><img src={ Check } /> New Task</Link>                                                                        
                                                </li>
                                                <li>
                                                    <Link><img src={ Hierarchy } /> Create Group</Link>
                                                </li>
                                                <li>                                                                        
                                                    <Link><img src={ Edit } /> Rename</Link>
                                                </li>
                                                <li>                                                                        
                                                    <Link><img src={ Duplication } /> Duplicate</Link>
                                                </li>
                                                <li>                                                                        
                                                    <Link><img src={ Share } /> Share</Link>
                                                </li>
                                                <li>                                                                        
                                                    <Link><img src={ Delete } /> Delete</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                }
                            > 
                                <img src={ MoreMenu } />
                            </Tooltip>
                            </span>                            
                        </div>                        
                        <TestComp />
                    </div>
                </div>
            </div>
            <div className='main_content_right'>
                <Activities/>
            </div>
        </React.Fragment>
    )
}


export default Projects;