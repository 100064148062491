import React, { useState } from "react";
import MoveIcon  from '../images/move.svg';
import Logo from '../images/logo-blue.svg';
import Assignee  from '../images/actions/add_assignee.svg';
import DueDate  from '../images/actions/due_date.svg';
import Priority  from '../images/actions/priority.svg';
import Tooltip from '@mui/material/Tooltip';
import { Link} from 'react-router-dom';
import SearchIcon  from '../images/search.svg';
import TeamMember  from '../images/team_member.png';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select' 


class RegistrationList extends React.Component {

    constructor(props) {
        super(props)
    } 
  
    render() {
        console.log("Rendering List, props.items = " + this.props.items.length)      
        return (
            <div>
                <table className="projects_table" >
                <thead>
                <tr>
                    <th>Task name</th>
                    <th>Assignee</th>
                    <th>Due Date</th>
                    <th>Priority</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                    {this.props.items.map( (item,i) => {
                        return (
                            <tr>
                                <td>
                                    <div className="collapsing_header">
                                        <span className="move_row"><img src={ MoveIcon } /></span>
                                        {item.firstName}
                                    </div>
                                </td>
                                <td>
                                    {/* <span className="assignee"><em className='bg_light_green'>S</em> <em className='bg_light_blue'>R</em> <em className='bg_light_pink'>K</em></span> */}
                                    <span className="assignee">
                                    {item.assignee.map( (assignee,indx) => {
                                        return (
                                            <em key={indx} >{assignee}</em>
                                        )
                                    })} 
                                    </span>
                                </td>
                                <td>{item.dueDate}</td>
                                <td><span className={`priority ${ item.priority }`}>{item.priority}</span></td>
                                <td><span className="status progress">In-Progress</span></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            </div>
        )
      
    }
  
}

class RegistrationApp extends React.Component {
  
    constructor(props) {
        super(props)
    
        // Initialize the form values
        this.state = { firstName:"", dueDate:"", assignee:[], high:false, normal:false, low:false, items:[] ,pr:'high' }
      
        // Bind the "this" for the callback functions 
        this.handleChangeRadio = this.handleChangeRadio.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.submitRegistration = this.submitRegistration.bind(this)
        this.removeItem = this.removeItem.bind(this)
    }
  
    // Process all change events for the form elements
    handleChange(event) {
        
      
        // If the input element isa checkbox, then use the checked property not the value
        if ( event.target.type == "checkbox" ) {
            this.setState( {[event.target.name]: event.target.checked} )                    
        } else {
            this.setState( {[event.target.name]: event.target.value} )          
        }
      
    }

   handleChangeRadio(event) {
       
    this.setState( {pr: event.target.value} )
      
      
    }
    
    // Adds a course registration item to the list of courses
    submitRegistration = (e) => {
        e.preventDefault()

        var priority = this.state.pr
        var assignee = (this.state.R ? "R" : "") + (this.state.M ? "M" : "")  + (this.state.S ? "S" : "")
        let assigneeArray =  assignee.split('');
       
      

        var itemsCopy = this.state.items.slice()
        itemsCopy.push( {firstName:this.state.firstName, dueDate:this.state.dueDate, assignee:assigneeArray, priority:priority } )
        
        this.setState ( { firstName:"", dueDate:"", assignee:[], R:false, M:false, S:false, items:itemsCopy } )
      
    }
  
    // Removes a specific item from the registered courses
    removeItem(index) {
    
        var itemsCopy = this.state.items.slice()
        itemsCopy.splice(index,1)
        this.setState( {items:itemsCopy} )
      
    }
  
    render() {
      
        return (
            <div>
                <RegistrationList items={this.state.items} removeItem={this.removeItem} />
                <form onSubmit={this.submitRegistration}>
                    <div className='add_project_sec'>
                        <input type="input" placeholder='+ Write a task name' className='form_control border0' name="firstName" value={this.state.firstName} onChange={this.handleChange}/>
                        <ul className='add_actions ddd'>
                            <li><span><img src={ Assignee } /></span>
                                <div className="dropdown_wrapper">
                                    <div className="custom_check">
                                        <input type="checkbox" id="ravi" name="R" checked={this.state.R}  onChange={this.handleChange} />
                                        <label for="ravi"><em>R</em>Ravi Kumar</label>
                                    </div>
                                    <div className="custom_check">
                                        <input type="checkbox" id="madhu" name="M" checked={this.state.M}  onChange={this.handleChange} />
                                        <label for="madhu"><em>M</em>Madhu Sanisetty</label>
                                    </div>
                                    <div className="custom_check">
                                        <input type="checkbox" id="syam" name="S" checked={this.state.S}  onChange={this.handleChange} />
                                        <label for="syam"><em>S</em>Syam Kumar</label>
                                    </div>
                                </div>
                            </li>
                            <li onclick="setDatepicker(this)">
                                <span><img src={ DueDate } /></span>
                                {/* <input type="date" id="dueDate" name="dueDate" value={this.state.dueDate} onChange={this.handleChange}/> */}
                            </li>
                            <li><span><img src={ Priority } /></span>
                                <div className="dropdown_wrapper">
                                    <div className="custom_radio">
                                        <input type="radio" id="high" name="pr" value="high"  checked={this.state.pr=='high'}  onChange={this.handleChangeRadio}  />
                                        <label for="high"><em></em>High</label>
                                    </div>
                                    <div className="custom_radio">
                                        <input type="radio" id="normal" name="pr" value="normal"  checked={this.state.pr=='normal'}  onChange={this.handleChangeRadio}  />
                                        <label for="normal"><em></em>Normal</label>
                                    </div>
                                    <div className="custom_radio">
                                        <input type="radio" id="low" name="pr" value="low"  checked={this.state.pr=='low'}  onChange={this.handleChangeRadio}  />
                                        <label for="low"><em></em>Low</label>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </form>
            </div>
            
        ) 
    }
  
}

export default RegistrationApp;