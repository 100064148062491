import React, { useState, useEffect  } from 'react';
import '../styles/scss/login.scss';
import '../styles/scss/components/simpleInput.scss';
 
 
 
//import { useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    // const history = new useHistory();
    useEffect(() => {
        document.body.classList.add('before-login')
        return () => {
          document.body.classList.remove('before-login')
        }
    }, [])
 

    return (
        <div className="login_page_wrapper">
            Login
        </div>
    )
}


export default Login;