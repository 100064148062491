import React from 'react';

function Users() {
    return (
        <div>
            users
        </div>
    )
}

export default Users;