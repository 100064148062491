import React, { useState} from 'react'; 
import RightArrowBlueCircle  from '../../images/right-arrow-blue-circle.svg'; 
import TeamMember  from '../../images/team_member.png';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
 

import "swiper/css";
import "swiper/css/navigation";

function TaskSummary() {

    const [isActive, setActive] = useState(1);

    const toggleClass = (value) => {
       
        setActive(value);
    };

    return (
        <React.Fragment>
            <div className='header_sec'>
                    <h6>Team Tasks Summary <img src={RightArrowBlueCircle} /></h6>
                    <div className='project_status'>
                        <ul>
                            <li className='new'>New</li>
                            <li className='completed'>Completed</li>
                            <li className='progress'>In-Progress</li>
                            <li className='overdue'>Overdue</li>
                        </ul>
                    </div>
                </div>
                <div className='task_sec team_task_sec'>
                    <div className='summary-dates'>
                        <Swiper 
                            slidesPerView={"auto"}
                            navigation={{
                                clickable: true,
                            }}
                            modules={[Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide 
                                className={isActive == 1 ? 'active nav-link': "nav-link"} 
                                onClick={() =>toggleClass(1)} 
                            >
                                Mon, 4 May
                            </SwiperSlide>
                            <SwiperSlide
                                className={isActive == 2 ? 'active nav-link': "nav-link"} 
                                onClick={() =>toggleClass(2)} 
                            >Tue, 5 May</SwiperSlide>
                            <SwiperSlide
                                className={isActive == 3 ? 'active nav-link': "nav-link"} 
                                onClick={() =>toggleClass(3)} 
                            >Wed, 6 May</SwiperSlide>
                            <SwiperSlide
                                className={isActive == 4 ? 'active nav-link': "nav-link"} 
                                onClick={() =>toggleClass(4)} 
                            >Thu, 7 May</SwiperSlide>
                            <SwiperSlide
                                className={isActive == 5 ? 'active nav-link': "nav-link"} 
                                onClick={() =>toggleClass(5)} 
                            >Fri, 8 May</SwiperSlide>
                            <SwiperSlide
                                className={isActive == 6 ? 'active nav-link': "nav-link"} 
                                onClick={() =>toggleClass(6)} 
                            >Sat, 9 May</SwiperSlide>
                            <SwiperSlide
                                className={isActive == 7 ? 'active nav-link': "nav-link"} 
                                onClick={() =>toggleClass(7)} 
                            >Sun, 10 May</SwiperSlide>
                            <SwiperSlide
                                className={isActive == 8 ? 'active nav-link': "nav-link"} 
                                onClick={() =>toggleClass(8)} 
                            >Sun, 10 May</SwiperSlide>
                            <SwiperSlide
                                className={isActive == 9 ? 'active nav-link': "nav-link"} 
                                onClick={() =>toggleClass(9)} 
                            >Sun, 10 May</SwiperSlide>
                            <SwiperSlide
                                className={isActive == 10 ? 'active nav-link': "nav-link"} 
                                onClick={() =>toggleClass(10)} 
                            >Sun, 10 May</SwiperSlide> 
                        </Swiper>
                    </div>
                    <div className='team_list_sec'>
                        <div className='team_member_row'>
                            <div className='member_details'>
                                <span className='member_avatar'><img src={TeamMember} /></span>
                                <span className='member_name'>Madhu S</span>
                            </div>
                            <div className='projects_status'>
                                <ul>
                                    <li className='new'><span>3</span></li>
                                    <li className='overdue'><span>10</span></li>
                                    <li className='progress'><span>10</span></li>
                                    <li className='completed'><span>5</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className='team_member_row'>
                            <div className='member_details'>
                                <span className='member_avatar'><img src={TeamMember} /></span>
                                <span className='member_name'>Kumar R</span>
                            </div>
                            <div className='projects_status'>
                                <ul>
                                    <li className='new'><span>6</span></li>
                                    <li className='overdue'><span>4</span></li>
                                    <li className='progress'><span>8</span></li>
                                    <li className='completed'><span>7</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className='team_member_row'>
                            <div className='member_details'>
                                <span className='member_avatar'><img src={TeamMember} /></span>
                                <span className='member_name'>Rajasekar E</span>
                            </div>
                            <div className='projects_status'>
                                <ul>
                                    <li className='new'><span>3</span></li>
                                    <li className='overdue'><span>2</span></li>
                                    <li className='progress'><span>4</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    )
}
export default TaskSummary;