import React from 'react';
import { Route } from 'react-router-dom';
import Login from '../views/login'; 
import Signup from '../views/Signup';
import Dashboard from '../views/dashboard'; 
import Projects from '../views/Projects'; 
import ForgotPassword from '../views/ForgotPassword.js';
 
import DataTable from '../views/components/table2';
import Users from '../views/Users';
import Components from '../views/Components'; 

function Router() {
    return (
        <React.Fragment>  
            <Route exact path="/login" >
                <Login/>
            </Route>
            <Route exact path="/forgotpassword" >
                <ForgotPassword/>
            </Route>
            <Route exact path="/signup" >
                <Signup/>
            </Route>
            <Route exact path="/" >
                <Dashboard/>
            </Route>
            <Route exact path="/dashboard" >
                <Dashboard/>
            </Route>
            <Route exact path="/projects" >
                <Projects/>
            </Route>
            <Route path='/users'  >
                <Users />
            </Route>
            <Route path='/basic-components'  >
                <Components />
            </Route>
            <Route path='/table2'>
                <DataTable />
            </Route>
        </React.Fragment>
    )
}

export default Router;