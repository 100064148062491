import React from 'react';
import SimpleInput from './components/simpleInput.js'; 
import SimpleSelect from './components/simpleselect.js';
import DateTable from './components/dataTable';

function BasicComponents() {
    return (
        <div className='pad20'>
            <SimpleInput/>
            <SimpleSelect/>

            <div>
                <DateTable/>
            </div>

        </div>
         
    )
}

export default BasicComponents;