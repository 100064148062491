import React from 'react';
import '../../styles/scss/components/simpleInput.scss';
import TextField from '@mui/material/TextField';

function Simpleinput() {
    return (
        <div className='form-group'> 
            <TextField id="outlined-basic" label="Sample Input" hiddenLabel variant="outlined" />
        </div>
    )
}

export default Simpleinput;