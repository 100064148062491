import React, { useEffect  }  from 'react'; 
//import { Link} from 'react-router-dom';
import '../styles/scss/login.scss';  
import '../styles/scss/components/simpleInput.scss';
 

import TextField from '@mui/material/TextField';
import { Link} from 'react-router-dom';

function Login() { 
    useEffect(() => {
        document.body.classList.add('before-login')
        return () => {
          document.body.classList.remove('before-login')
        }
    }, [])
    return (
        <div className="login_page_wrapper"> 
         
        </div>
    )
}


export default Login;