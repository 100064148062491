import React, { useState} from 'react'; 
import Logo from '../../images/logo-blue.svg';
//import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ImmiLogo from '../../images/immi_logo.svg';
import Select from 'react-select' 

function ProjectsSummary() {

    const data = [
        
        {
            projectId: '01',
            projectName: 'All Projects',
            projectNameShort: 'All'
        },
        {
            projectId: '02',
            projectName: 'Immibox',
            projectNameShort: 'IB'
        },
        {
            projectId: '03',
            projectName: 'ProfitX',
            projectNameShort: 'PF'
        },
        {
            projectId: '04',
            projectName: 'Qlytix',
            projectNameShort: 'QX'
        },
      ]


    const [projectData, setProjectData] = React.useState(data);
    
    const onChange = (e, projectId) => {
        const { name, value } = e.target
    
        const editData = projectData.map((item) =>
        item.projectId === projectId && name ? { ...item, [name]: value } : item
        )
    
        setProjectData(editData)
    }

    const daysData = [
        
        {
            dayId: '01',
            daysName: 'This Week',
        },
        {
            dayId: '02',
            daysName: 'Yesterday',
        },
        {
            dayId: '03',
            daysName: 'Today',
        },
      ]

    const projectOptions = [
        { value: 'All', label: 'All Projects' },
        { value: 'IB', label: 'Immibox' },
        { value: 'PF', label: 'ProfitX' },
        { value: 'QX', label: 'Qlytix' }
    ]

    const weekOptions = [
        { value: '01', label: 'This Week' },
        { value: '02', label: 'Yesterday' },
        { value: '03', label: 'Today' }
    ]


    const [daysallData, setdaysData] = React.useState(daysData);
    
    const ondaysChange = (e, dayId) => {
        const { name, value } = e.target
    
        const editData = daysallData.map((item) =>
        item.dayId === dayId && name ? { ...item, [name]: value } : item
        )
    
        setdaysData(editData)
    }


    const [isActive, setActive] = useState(1);

    const toggleClass = (value) => {
       
        setActive(value);
    };
    

    return (
        <React.Fragment>
            <div className='header_sec'>
                <div className="project_dropdown">
                    {/* <Select
                        className='customselect db_project_select'
                        defaultValue={'All Projects'}
                        >
                        {projectData.map(({ projectId, projectName, projectNameShort }) => (
                            <MenuItem 
                            value={projectName}
                            name="projectName"
                            className='cust_pr_drpdwn'
                            onChange={(e) => onChange(e, projectId)}>
                            <div className={`project_logo ${ projectNameShort}`}>{projectNameShort}</div>
                            { projectName } </MenuItem>
                        ))}
                    </Select> */}
                    <Select className='custom_select db_project_select'
                        closeMenuOnSelect={true}
                        defaultValue={projectOptions[0]}
                        classNamePrefix="custom_select_inner"
                        styles={{
                            option: (base) => ({
                                ...base,
                                fontFamily: `$primaryfontMedium`,
                                fontSize: '12px',
                                height: '100%',
                                fontWeight: '500'
                            }),
                            }}                              
                        options={projectOptions}
                    />
                </div>
                <div className='header_actions'>
                    {/* <Select
                        className='customselect'
                        defaultValue={'This Week'}
                        >
                        {daysallData.map(({ dayId, daysName }) => (
                            
                            <MenuItem 
                            value={daysName}
                            name="daysName"
                            onChange={(e) => ondaysChange(e, dayId)}>{ daysName } </MenuItem>
                        ))}
                    </Select> */}
                    <Select className='custom_select'
                        closeMenuOnSelect={true}
                        defaultValue={weekOptions[0]}
                        classNamePrefix="custom_select_inner"
                        styles={{
                            option: (base) => ({
                                ...base,
                                fontFamily: `$primaryfontMedium`,
                                fontSize: '12px',
                                height: '100%',
                                fontWeight: '500'
                            }),
                            }}                              
                        options={weekOptions}
                    />
                    <ul className='filters'>
                        <li className={isActive == 1 ? 'active': ""} 
                                onClick={() =>toggleClass(1)}>All Team</li>
                        <li className={isActive == 2 ? 'active': ""} 
                                onClick={() =>toggleClass(2)}>My Tasks</li>
                    </ul>
                </div>
            </div>
            <div className='task_sec'>
                <div className='task_summary'>
                    <div className='chart_area'>
                        <p>Task Summary</p>
                        <div className='chart'>
                            <div className='chart_count'>
                                <span className='count'>29</span>
                                <span className='total'>total</span>
                            </div>
                        </div>
                    </div>
                    <div className='chart_labels'>
                        <ul>
                            <li className='completed'><em>11</em>Completed</li>
                            <li className='progress'><em>5</em>In-Progress</li>
                            <li className='overdue'><em>9</em>Overdue</li>
                            <li className='closed'><em>4</em>Closed</li>
                        </ul>
                    </div>
                </div>
                <div className='projects_list'>
                    <ul>
                        <li>
                            <div className="project_logo"><img src={Logo} /></div>
                            <div className='project_status'>
                                <span className='project_name'>Excelry</span>
                                <ul>
                                    <li className='completed'><em>5</em>Completed</li>
                                    <li className='progress'><em>4</em>In-Progress</li>
                                    <li className='overdue'><em>3</em>Overdue</li>
                                    <li className='closed'><em>1</em>Closed</li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div className="project_logo"><img src={ImmiLogo} /></div>
                            <div className='project_status'>
                                <span className='project_name'>ImmiBox</span>
                                <ul>
                                    <li className='completed'><em>6</em>Completed</li>
                                    <li className='progress'><em>1</em>In-Progress</li>
                                    <li className='overdue'><em>6</em>Overdue</li>
                                    <li className='closed'><em>3</em>Closed</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ProjectsSummary;